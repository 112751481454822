import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Button } from "../../Button";
import TecGym from "../../../assets/images/WrapperContent/tec-gym-content.png";
import * as S from "../styles";

export default function ModalFit({ open, setOpen }) {
  const [email_fitness, setEmailFitness] = useState("");
  const [isLoading, setisLoading] = useState(false);

  async function saberMaisClick() {
    setOpen(false);
    setTimeout(()=>window.location.href='/#contact',500);
    return true;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <S.ModalFitness>
        <h3>Conheça o Boot Camp Guepardo</h3>
        <S.TextoModalFitness>
          <S.ImageFitness src={TecGym} />
          <p>
            Nós da Guepardo estamos ansiosos com o lançamento previsto para 2023
            do espaço mais moderno de Curitiba e região para treinamento estilo
            Boot Camp. <br />
            <br />
            Este espaço contará com equipamentos importados de altíssima
            qualidade da marca TechnoGym, a fornecedora oficial das Olimpíadas
            Tóquio 2020.
  

            <Button onClick={saberMaisClick}>
              Quero saber mais
            </Button>

          </p>
        </S.TextoModalFitness>
        {/* <div>
          <S.InputTextFitness
            label="Entre com seu e-mail para receber novidades"
            variant="outlined"
            value={email_fitness}
            type="text"
            onChange={(e) => setEmailFitness(e.target.value)}
          />
          <Button onClick={sendFormFitness} disabled={isLoading}>
            {isLoading ? "aguarde..." : "enviar"}
          </Button>
        </div> */}
      </S.ModalFitness>
    </Dialog>
  );
}
