import React from "react";
import { Benefits } from "../../components/Benefits";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";
import Main from "../../components/Main";
import { PlanContainer2 } from "../../components/PlanContainer";
import { MdAccessTime } from "react-icons/md";
import Image from "../../assets/images/card/image-1-card.png";

import {
  ContainerDays,
  ListCardContainer,
  OperatingDays,
  TextClassroom,
} from "../Home/styles";
import { AthleteCard } from "../../components/AthleteCard";
import { Button } from "../../components/Button/";
import { useMediaQuery } from "@material-ui/core";

const MOCK_CARD = {
  imageCard: Image,
  linkCard: "",
  titleCard: "Conheça a Guepardo Boot Camp",
  paragraphCard: "(em breve)",
};

function Fitness() {
  const isMobile = useMediaQuery("(max-width: 515px)");

  return (
    <section>
      <Main
        bgImage={isMobile ? "/fitness-mobile.jpg" : "/fit-bg.png"}
        title={"DISPONÍVEL EM BREVE"}
        redirect="#contact"
      />
      <ListCardContainer>
        <div>
          <AthleteCard noContent {...MOCK_CARD} />
        </div>
        <ContainerDays style={{ display: "none" }}>
          <Button bgColor="#ffa500">
            <MdAccessTime />
            <TextClassroom>Aulas</TextClassroom>
            <div>
              <OperatingDays>
                Seg a Sex às 6h, 8h, 10h, 12h, 14h, 17h, 18h e 20h
              </OperatingDays>
              <OperatingDays>Sábados às 8h e 10h</OperatingDays>
            </div>
          </Button>
        </ContainerDays>
      </ListCardContainer>
      {/* <WrapperContent 
        logo={TecGym}
        titleText="Conheça nossa estrutura Boot Camp"
        paragraph="Mollis cubilia maximus mattis quisque fringilla orci cursus pharetra dignissim posuere, tortor eros tellus egestas magnis quis nisi iaculis at, habitasse leo curabitur fermentum ac tristique class senectus sapien. Magna neque dis nam et donec odio vivamus porttitor suscipit, nullam risus quisque ipsum quam maximus vulputate vehicula facilisis placerat, orci arcu egestas fermentum sem porta consequat cursus. "
      />
      <OperatingCycle style={{display:'none'}} /> */}
      <PlanContainer2 />
      <Benefits />
      <Contact subject="Contato via página Fitness" />
      <Footer />
    </section>
  );
}

export { Fitness };
