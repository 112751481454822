import styled from "styled-components";

const TeamMemberContainer = styled.div`
  width: 100%;
  max-width: 165px;
`;

const TeamMemberContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  margin-top: 45px;
  max-width: 150px;

  > a > button > span {
    font-size: 11px;
    margin-left: 7px;
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  width: 78px;
  height: 78px;
  background-image: url("/dotted.png");
  background-size: 100%;
  background-repeat: no-repeat;

  > img {
    position: absolute;
    bottom: -45px;
    right: -44px;
    width: 106px;
  }
`;

const NameMemberTeam = styled.h3`
  text-align: center;
  text-transform: capitalize;
  color: white;
  max-width: 102px;
  margin-top:10px;
  font-size:16px;
`;

const JobType = styled.h3`
  color: #ffa500;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

export {
  HeaderContainer,
  TeamMemberContainer,
  TeamMemberContent,
  NameMemberTeam,
  JobType,
};
