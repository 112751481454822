import React from "react";
import * as S from "./styles";

function HamburguerMenu() {
  return (
    <S.ContainerMenu>
      <S.WrapperMenu />
    </S.ContainerMenu>
  );
}

export { HamburguerMenu };
