import React, { useState } from "react";
import { LIST_MENU } from "../../../__mocks__/Header/menu";
import ModalFit from "../ModalFit";
import * as S from "./styles";

function ContentMenuMobile({ setCheck, check }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <S.MenuMobileContainer>
      <ModalFit open={showModal} setOpen={setShowModal} />
      {LIST_MENU.map(({ menuItem, redirect }) => (
        <React.Fragment key={menuItem}>
          <S.NewItemMenu
            onClick={() => {
              setCheck(false);
              setTimeout(() => {
                if (menuItem === "Fitness") {
                  return setShowModal(true);
                }
              }, 950);
            }}
          >
            <a href={redirect !== "/fitness" ? redirect : undefined}>
              {menuItem}
            </a>
          </S.NewItemMenu>
        </React.Fragment>
      ))}
    </S.MenuMobileContainer>
  );
}

export { ContentMenuMobile };
