import styled from "styled-components";

const ListBenefits = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > img {
    height: 138px;
  }
`;

const ListBenefitsContainer = styled.section`
  background-color: #feb415;
  min-height: 250px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 20px 10px;

  > h2 {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export { ListBenefits, ListBenefitsContainer };
