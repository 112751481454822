import styled from "styled-components";

const ScheduleContainer = styled.div`
  width: 354px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: #ffa500;
  cursor:pointer;

  @media (max-width: 603px) {
    gap: 24px;
    width: 312px;
    height: 58px;
  }
`;

const ImageCheetah = styled.img`
  height: 65px;
  transform: rotate(86deg);
`;

const TextSchedules = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: white;
`;

export { ScheduleContainer, ImageCheetah, TextSchedules };
