const LIST_BASIC_MONTHLY = [
  "Frequência ilimitada",
  "Cancele quando quiser",
  "Sem taxa de adesão",
];

const LIST_BASIC_YEAR = [
  "Treine 3x na semana",
  "Cancele quando quiser",
  "Sem taxa de adesão",
];

const LIST_VIP_MONTHLY = [
  "Frequência ilimitada",
  "30 dias de trancamento",
  "Sem taxa de adesão",
];

const LIST_VIP_YEAR = [
  "Treine 3x na semana",
  "30 dias de trancamento",
  "Sem taxa de adesão",
];

export { LIST_BASIC_MONTHLY, LIST_BASIC_YEAR, LIST_VIP_MONTHLY, LIST_VIP_YEAR };
