import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const ContactContainer = styled.section`
  padding: 50px 10px 50px 10px;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 31px;
  @media (max-width: 300px) {
    width: 100%;
  }
`;

const ContentContact = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ConfirmContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > img {
    width: 60%;
    max-width: 300px;
  }

  > div {
    width: 30%;
  }

  @media (max-width: 470px) {
    flex-direction: column;

    > div {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

const SocialNetworkContainer = styled.div`
  width: 100%;
  max-width: 595px;

  > h3 {
    margin-bottom: 20px;
    letter-spacing: 1.4px;
    font-size: 26px;
  }
`;

const PositionNetwork = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;

  @media (max-width: 920px) {
    gap: 14px;
  }

  > div > p {
    margin: 10px 0 5px 18px;
  }

  > div > a > button {
    width: 167px;
  }

  > div > a > button > span {
    margin-left: 7px;
  }
`;

const LocationContainer = styled.div`
  margin-top: 30px;

  > aside {
    margin-left: 12px;
  }
`;

const TextLocation = styled.p`
  margin: 20px 0 5px 0;

  > img {
    padding-right: 5px;
  }
`;

const TextState = styled.p`
  padding-left: 20px;
`;

const MapContainer = styled.div`
  background-image: url("/maps.png");
  width: 100%;
  max-width: 466px;
  height: 232px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  svg {
    font-size: 20px;
  }

  > button {
    gap: 5px;
    width: 195px;
    height: 40px;
    position: absolute;
    bottom: -23px;
    right: 30px;
    font-weight: 500;
    text-transform: none;
    background-color: #fba800;
  }

  @media (max-width: 1066px) {
    margin: 20px 0 70px 0;
  }
`;

const TitleForm = styled.p`
  max-width: 462px;
`;

const InputText = styled(TextField)`
  background: #f4f6f6;
  color: #54565a;

  > label {
    font-weight: bold;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export {
  ContactContainer,
  FormContainer,
  ContentContact,
  ConfirmContainer,
  SocialNetworkContainer,
  PositionNetwork,
  LocationContainer,
  TextLocation,
  TextState,
  MapContainer,
  TitleForm,
  InputText,
};
