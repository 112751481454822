import React from "react";
import { Button } from "../Button";
import * as S from "./styles";

function PlanCard({
  planType,
  planQuantity,
  planPeriod,
  planValue,
  planItemList,
  bgColor,
  bt,
  ...restProps
}) {
  return (
    <S.PlanCardContainer {...restProps}>
      <S.PlanTypeContainer
        headerColor={planType === "Básico" ? "#54565A" : "#777"}
      >
        <h2>{planType}</h2>
      </S.PlanTypeContainer>
      <S.HeaderContainer
        headerColor={planQuantity === "PLANO BLACK" ? "#000" : "#ffa500"}
        headerFontColor={planQuantity === "PLANO BLACK" ? "#FFF" : "#000"}
      >
        <span>{planQuantity}</span>
        <div>{planPeriod}</div>
      </S.HeaderContainer>
      <S.PlanCardContent>
        <S.MonthlyValue>
          <span>Valor Mensal</span>
          <h3>R${planValue}</h3>
        </S.MonthlyValue>
        <S.PlanDescription>
          {planItemList.map((item) => (
            <S.PlanItem key={item}>
              <S.ItemMarker bgColor={bgColor} />
              <span>{item}</span>
            </S.PlanItem>
          ))}
        </S.PlanDescription>
      </S.PlanCardContent>
      <S.ButtonContainer bt={bt}>
        <Button mh="34px">Assinar</Button>
      </S.ButtonContainer>
    </S.PlanCardContainer>
  );
}

export { PlanCard };
