import React, { useState } from "react";

import { Button } from "../../components/Button";
import { AthleteCardMini } from "../../components/AthleteCard";
import { FeedbackCard } from "../../components/FeedbackCard";
import { Benefits } from "../../components/Benefits";
import Main from "../../components/Main";
import { LIST_DATA_CARD } from "../../__mocks__/Card";
import { LIST_FEEDBACK } from "../../__mocks__/FeedbackCard";
import { TeamCard } from "../../components/TeamCard";
import { LIST_MEMBERS } from "../../__mocks__/MemberTeam";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";
import { MdAccessTime } from "react-icons/md";
import { Dialog } from "@mui/material";
import { IMAGES_CAROUSEL } from "../../__mocks__/Home";

import * as S from "./styles";
import { useMediaQuery } from "@material-ui/core";

function Home() {
  const [idImage, setIdImage] = useState(false);

  function renderingImage() {
    const OBJECT_IMAGE = IMAGES_CAROUSEL.find(({ id }) => idImage === id);
    return <S.ImageCarousel {...OBJECT_IMAGE} />;
  }

  function Shuffle(arr) {
    return [...arr].sort(() => Math.random() - 0.5);
  }

  const isMobile = useMediaQuery("(max-width: 520px)");

  return (
    <S.ContainerHome>
      <Dialog open={Boolean(idImage)} onClose={() => setIdImage(false)}>
        {renderingImage()}
      </Dialog>
      <Main
        bgImage={isMobile ? "/banner-home-mobile.jpg" : "/banner-home.jpg"}
        title="CONHEÇA NOSSOS SERVIÇOS"
        redirect="#our-services"
      />
      <S.AboutTheAcademy id="quemsomos">
        <h1>Guepardo Fitness Club Academia em Curitiba</h1>
        <S.ContentAboutAcademy id="quemsomos">
          <S.ContainerCarousel itemsToShow={1} showArrows={false}>
            {IMAGES_CAROUSEL.map(({ ...allProps }) => (
              <S.ImageCarousel
                key={allProps.id}
                onClick={() => setIdImage(allProps.id)}
                {...allProps}
              />
            ))}
          </S.ContainerCarousel>
          <S.ContainerText>
            <p>
              A GUEPARDO, foi inaugurada em março de 2015, começou como um sonho
              pequeno e foi se tornando grande e audacioso. Ana Leandro,
              proprietária, educadora física e apaixonada por esportes carrega
              desde criança habilidades esportivas e o sonho de construir uma
              marca reconhecida no universo fitness. A GUEPARDO, afiliada a
              CrossFit Inc. , iniciou oportunizando aos seus alunos uma
              experiência diferenciada em estrutura e atendimento.
            </p>
            <p>
              <i>"Entendo a prática frequente de exercícios como um lifestyle, seja
              ele crossfit ou qualquer outro exercício programado e treinado
              constantemente, pensando nisso, além da comunidade e dos treinos,
              queremos proporcionar um lugar de sensações e experiências
              incríveis em um ambiente moderno e conceitual, nossos alunos
              merecem!"</i> - Ana Leandro.
            </p>

            <div>
              <a href="#contact">
                <Button>entrar em contato</Button>
              </a>
            </div>
          </S.ContainerText>
        </S.ContentAboutAcademy>
      </S.AboutTheAcademy>
      <S.ListCardContainer>
        <div id="our-services">
          {LIST_DATA_CARD.map(({ ...allProps }) => (
            <AthleteCardMini key={allProps.titleCard} {...allProps} />
          ))}
        </div>
        <S.ContainerDays>
          <Button mh="52px" bgColor="#ffa500">
            <MdAccessTime />
            <S.TextClassroom>Aulas</S.TextClassroom>
            <div>
              <S.OperatingDays>
                Seg a Sex às 6h, 8h, 10h, 12h, 14h, 17h, 18h e 20h
              </S.OperatingDays>
              <S.OperatingDays>Sábados às 8h e 10h</S.OperatingDays>
            </div>
          </Button>
        </S.ContainerDays>
      </S.ListCardContainer>
      <S.Container>
        <h3>Experiência que transforma</h3>
        <S.ListFeedbackContainer>
          {Shuffle(LIST_FEEDBACK)
            .slice(0, 2)
            .map(({ ...allProps }) => (
              <FeedbackCard key={allProps.messageFeedback} {...allProps} />
            ))}
        </S.ListFeedbackContainer>
      </S.Container>
      <Benefits />
      <S.MembersTeam>
        <h1>Nosso Time</h1>
        <div>
          {LIST_MEMBERS.map(({ ...allProps }) => (
            <TeamCard key={allProps.nameMember} {...allProps} />
          ))}
        </div>
      </S.MembersTeam>
      <Contact subject="Contato via home-page" />
      <Footer />
    </S.ContainerHome>
  );
}

export { Home };
