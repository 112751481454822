import styled from "styled-components";

const HeaderContainer = styled.header`
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  @media (max-width: 620px) {
    background-size: 100% 100%;
  }

  @media (max-width: 428px) {
    background-size: contain;
    background-position: initial;
    height: 50vh;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const MotivationMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 84px;
  max-height: 76%;
  height: calc(100% - 85px);

  @media (max-width: 620px) {
    margin-bottom: 50px;
  }
  @media (max-width: 481px) {
    margin-bottom: -20px;
  }

  > img {
    @media (max-width: 295px) {
      width: 150px;
    }
  }
`;

const MotivationMessage = styled.h2`
  color: white;
  text-align: center;
`;

const SizeButtonContainer = styled.div`
  max-width: 280px;
  width: 100%;
`;

const PositionScheduleContainer = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  right: -137px;
  top: 152px;

  @media (max-width: 603px) {
    right: -127px;
    top: 140px;
  }
`;

export {
  HeaderContainer,
  ContentHeader,
  MotivationMessageContainer,
  MotivationMessage,
  SizeButtonContainer,
  PositionScheduleContainer,
};
