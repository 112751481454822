import styled from "styled-components";

const ContainerMenu = styled.section`
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 103;
`;

const WrapperMenu = styled.div`
  width: 30px;
  height: 2px;
  content: "";
  background-color: white;
  margin: 10px 0;
  display: block;
  position: absolute;
  transition: all 0.5s ease-in-out 0s;

  :before,
  :after {
    width: 100%;
    height: 100%;
    content: "";
    background-color: white;
    display: block;
    transition: all 0.5s ease-in-out 0s;
    position: absolute;
  }
  :before {
    top: -10px;
  }
  :after {
    bottom: -10px;
  }
`;

export { WrapperMenu, ContainerMenu };
