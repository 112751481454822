import React from "react";
import Squat from "../../assets/images/WrapperContent/squat-content.png";
import Bar from "../../assets/images/WrapperContent/bar-content.png";
import Cashier from "../../assets/images/WrapperContent/cashier-content.png";
import SquatTwo from "../../assets/images/WrapperContent/squat2-content.png";
import nutri1 from "../../assets/images/WrapperContent/n1.jpg";
import nutri2 from "../../assets/images/WrapperContent/n2.jpg";
import nutri3 from "../../assets/images/WrapperContent/n3.jpg";
import nutri4 from "../../assets/images/WrapperContent/n4.jpg";
import aval1 from "../../assets/images/WrapperContent/aval1.jpg";
import aval2 from "../../assets/images/WrapperContent/aval2.jpg";
import aval3 from "../../assets/images/WrapperContent/aval3.jpg";
import aval4 from "../../assets/images/WrapperContent/aval4.jpg";


import * as S from "./styles";
import {
  ItemMarker,
  MonthlyValue,
  PlanCardContainer,
  PlanItem,
} from "../PlanCard/styles";
import { Button } from "../Button";

function WrapperContent({ logo, titleText, paragraph, card }) {
  return (
    <S.WrapperContainer>
      <S.ContentText>
        {logo && <img src={logo} width="146px" alt="imagem de cross" />}
        <h3>{titleText}</h3>
        <p dangerouslySetInnerHTML={{__html: paragraph}} />
        {card && (
          <PlanCardContainer mh="200px" bgMaster="#F4F6F6">
            <S.Header>
              <span>{card.titleCard || "Avaliação Física"}</span>
              <div>{card.typeCard || "Avaliação"}</div>
            </S.Header>
            <S.CardContent>
              <MonthlyValue>
                <span>Valor</span>
                <h3>{card.value || "R$160,00"}</h3>
              </MonthlyValue>
              <PlanItem>
                <ItemMarker />
                <span>{card.item || "Avaliação completa"}</span>
              </PlanItem>
              <PlanItem>
                <ItemMarker />
                <span>Outra característica importante</span>
              </PlanItem>
			  <a href="#contact">
				<Button width="60%">Agendar avaliação</Button>
				</a>
            </S.CardContent>
          </PlanCardContainer>
        )}
      </S.ContentText>
      <S.ContentImage>
        <div>
          <img src={Squat} width="180px" alt="imagem do atleta" />
          <img src={Bar} width="180px" alt="imagem do atleta" />
        </div>
        <div>
          <img src={Cashier} width="180px" alt="imagem do atleta" />
          <img src={SquatTwo} width="180px" alt="imagem do atleta" />
        </div>
      </S.ContentImage>
    </S.WrapperContainer>
  );
}


function WrapperContent_Nutri({ logo, titleText, paragraph, card }) {
  return (
    <S.WrapperContainer>
      <S.ContentText>
        {logo && <img src={logo} width="146px" alt="imagem de cross" />}
        <h3>{titleText}</h3>
        <p dangerouslySetInnerHTML={{__html: paragraph}} />
        {card && (
          <PlanCardContainer mh="200px" bgMaster="#F4F6F6">
            <S.Header>
              <span>{card.titleCard || "Avaliação Física"}</span>
              <div>{card.typeCard || "Avaliação"}</div>
            </S.Header>
            <S.CardContent>
              <MonthlyValue>
                <span>Valor</span>
                <h3>{card.value || "R$160,00"}</h3>
              </MonthlyValue>
              <PlanItem>
                <ItemMarker />
                <span>{card.item || "Avaliação completa"}</span>
              </PlanItem>
			  <a href="#contact">
				<Button width="60%">Agendar avaliação</Button>
				</a>
            </S.CardContent>
          </PlanCardContainer>
        )}
      </S.ContentText>
      <S.ContentImage>
        <div>
          <img src={nutri1} width="180px" alt="imagem do atleta" />
          <img src={nutri2} width="180px" alt="imagem do atleta" />
        </div>
        <div>
          <img src={nutri3} width="180px" alt="imagem do atleta" />
          <img src={nutri4} width="180px" alt="imagem do atleta" />
        </div>
      </S.ContentImage>
    </S.WrapperContainer>
  );
}

function WrapperContent_Avaliacao({ logo, titleText, paragraph, card }) {
  return (
    <S.WrapperContainer>
      <S.ContentText>
        {logo && <img src={logo} width="146px" alt="imagem de cross" />}
        <h3>{titleText}</h3>
        <p dangerouslySetInnerHTML={{__html: paragraph}} />
        {card && (
          <PlanCardContainer mh="200px" bgMaster="#F4F6F6">
            <S.Header>
              <span>{card.titleCard || "Avaliação Física"}</span>
              <div>{card.typeCard || "Avaliação"}</div>
            </S.Header>
            <S.CardContent>
              <MonthlyValue>
                <span>Valor</span>
                <h3>{card.value || "R$160,00"}</h3>
              </MonthlyValue>
              <PlanItem>
                <ItemMarker />
                <span>{card.item || "Avaliação completa"}</span>
              </PlanItem>
			  <a href="#contact">
				<Button width="60%">Agendar avaliação</Button>
			  </a>
            </S.CardContent>
          </PlanCardContainer>
        )}
      </S.ContentText>
      <S.ContentImage>
        <div>
          <img src={aval1} width="180px" alt="imagem do atleta" />
          <img src={aval2} width="180px" alt="imagem do atleta" />
        </div>
        <div>
          <img src={aval3} width="180px" alt="imagem do atleta" />
          <img src={aval4} width="180px" alt="imagem do atleta" />
        </div>
      </S.ContentImage>
    </S.WrapperContainer>
  );
}


export { WrapperContent, WrapperContent_Nutri, WrapperContent_Avaliacao };
