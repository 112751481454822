import ScrollButton from "./components/ScrollButton";
import { AllRoutes } from "./routes/routes";

function App() {
  return (
    <div>
      <AllRoutes />
      <ScrollButton />
    </div>
  );
}

export default App;
