import styled from "styled-components";

export const Button = styled.div`
  position: fixed;
  width: 50px;
  left: 94%;
  bottom: 40px;
  border-radius: 50%;
  height: 51px;
  display: flex;
  font-size: 3rem;
  z-index: 100;
  cursor: pointer;
  justify-content: center;
  align-items: flex-start;
  color: white;
  margin: 0 -13px;
  background-color: #2e302ee0;

  @media (max-width: 645px) {
    left: 85%;
  }
`;
