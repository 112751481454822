import Rogue from "../../assets/images/Partners/partners-rogue.png";
import Cross from "../../assets/images/Partners/partners-cross.png";
import Gym from "../../assets/images/Partners/partners-gym.png";

const LIST_PARTNERS = [
  {
    link: "https://www.roguefitness.com/",
    src: Rogue,
    alt: "logo da rogue",
    height: "29px",
  },
  {
    link: "https://www.crossfit.com/",
    src: Cross,
    alt: "logo do crossfit",
  },
  {
    link: "https://www.technogym.com/br/",
    src: Gym,
    alt: "logo da Gym",
    width: "150px",
  },
];

export { LIST_PARTNERS };
