const LIST_MENU = [
  { menuItem: "Home Page", redirect: "/" },
  { menuItem: "Quem Somos", redirect: "/#quemsomos" },
  { menuItem: "Crossfit", redirect: "/crossfit" },
  { menuItem: "Fitness", redirect: "/fitness" },
  { menuItem: "Avaliação Física", redirect: "/avaliacao" },
  //{ menuItem: "Nutrição", redirect: "/nutricao" },
  { menuItem: "Personal", redirect: "/personal" },
  { menuItem: "Contato", redirect: "#contact" },
];

export { LIST_MENU };
