import ImageOne from "../../assets/images/card/image-1-card.png";
import ImageTwo from "../../assets/images/card/image-2-card.png";

const LIST_DATA_CARD = [
  {
    imageCard: ImageTwo,
    titleCard: "Crossfit Guepardo",
	linkCard: "/crossfit",
    paragraphCard:
      "Crossfit é um programa de treinamento físico geral que mistura diferentes atividades como: exercícios com o próprio peso corporal, baseados em calistenia e movimentos ginásticos, levantamento de peso e movimentos que envolvam cargas externas e exercícios como correr, pedalar e remar entre outros.",
  },
  {
    imageCard: ImageOne,
    titleCard: "Boot Camp Guepardo",
	linkCard: "",
    paragraphCard:"<center>(em breve)</center>",
	noContent:true
  },
];

export { LIST_DATA_CARD };
