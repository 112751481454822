import { useMediaQuery } from "@material-ui/core";
import React from "react";
import guepardoWhite from "../../assets/images/guepardo-white.svg";
import * as S from "./styles";

export default function ScheduleYourTime() {
  const isMobile = useMediaQuery("(max-width: 620px)");

  if (isMobile) {
    return null;
  }

  return (
    <S.ScheduleContainer
      onClick={() => window.open("https://wa.me/5541992312727")}
    >
      <S.TextSchedules>AGENDE SEU HORÁRIO</S.TextSchedules>
      <S.ImageCheetah src={guepardoWhite} alt="guepardo branco" />
    </S.ScheduleContainer>
  );
}
