import React from "react";
import Guepardo from "../../assets/images/guepardo-yellow.svg";
import TextGuepardo from "../../assets/images/text-guepardo.svg";
import { LIST_PARTNERS } from "../../__mocks__/Partners";

import * as S from "./styles";

function Footer() {
  return (
    <S.ContainerFooter>
      <S.HeaderFooter>
        <S.AcademyCertification>
          <img src={Guepardo} width="138px" alt="Imagem do guepardo" />
          <img
            src={TextGuepardo}
            width="100%"
            height="50px"
            alt="Imagem do guepardo"
          />
          <S.TextFooter textAlign="center">
            A Guepardo é certificada oficialmente pela CrossFit. No mundo
            inteiro, CrossFit é uma marca consagrada e que treina os maiores
            atletas desta modalidade.
          </S.TextFooter>
        </S.AcademyCertification>
        <S.ContactFooter>
          <S.ContactContent>
            <div>
              <S.TitleFooter>Contato</S.TitleFooter>
              <a
                href="tel:554130146283"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <S.TextFooter lh="1.5">(41) 3014-6283</S.TextFooter>
              </a>
              <a
                href="https://web.whatsapp.com/send?phone=5541992312727"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <S.TextFooter lh="1.5">(41) 99231-2727</S.TextFooter>
              </a>
              <a
                href="mailto:contato@crossfitguepardo.com"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <S.TextFooter lh="1.5">
                  contato@crossfitguepardo.com
                </S.TextFooter>
              </a>
            </div>
            <div>
              <S.TitleFooter>Endereço</S.TitleFooter>
              <S.TextFooter lh="1.5">Rua Estados Unidos, 1579</S.TextFooter>
              <S.TextFooter lh="1.5">Bacacheri — 82510-050</S.TextFooter>
              <S.TextFooter lh="1.5">Curitiba/Paraná</S.TextFooter>
            </div>
          </S.ContactContent>
          <div>
            <S.TitleFooter>Horários de funcionamento</S.TitleFooter>
            <S.OpeningHoursContainer>
              <div>
                <S.TextFooter>Seg - Qui</S.TextFooter>
                <S.TextFooter>6:00 – 22:00h</S.TextFooter>
              </div>
              <div>
                <S.TextFooter>Sex</S.TextFooter>
                <S.TextFooter>6:00 – 21:00h</S.TextFooter>
              </div>
              <div>
                <S.TextFooter>Sáb</S.TextFooter>
                <S.TextFooter>9:00 – 11:00h</S.TextFooter>
              </div>
            </S.OpeningHoursContainer>
          </div>
        </S.ContactFooter>
      </S.HeaderFooter>
      <S.PartnersContainer>
        <S.TitleFooter>Parceiros</S.TitleFooter>
        <S.LogoPartners>
          {LIST_PARTNERS.map(({ ...allProps }) => (
            <a href={allProps.link} target="_blank"><img key={allProps.src} {...allProps} alt={allProps.alt} /></a>
          ))}
        </S.LogoPartners>
      </S.PartnersContainer>
    </S.ContainerFooter>
  );
}

export { Footer };
