import React, { useState } from "react";
import { maskPhone } from "../../utils/maskPhone";
import { Button } from "../Button";
import ReCAPTCHA from "react-google-recaptcha";
import {
  BsWhatsapp,
  BsFillTelephoneFill,
  BsFillCursorFill,
  BsFillGeoAltFill,
  BsFileEarmarkEasel,
} from "react-icons/bs";

import * as S from "./styles";

function Contact({ subject }) {
  const [phone, setPhone] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [isVerify, setVerify] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  
  function changeValue(value) {
    setToken(value);
    setVerify(!isVerify);
  }

  async function sendForm() {
    if (nome.length == 0)
    {
      alert(
        "Por favor, informe seu Nome"
      );
      return false;
    }
    else if (phone.length == 0)
    {
      alert(
        "Por favor, informe seu Telefone"
      );
      return false;
    }
    else
    {
      setLoading(true); 
      const secret = process.env.REACT_APP_RECAPTCHA_PRIVATE_KEY;
      const email_url = process.env.REACT_APP_BASE_EMAIL_URL;
      try {
        const send_url = `${email_url}send.php`;
        const _data = {
          secret: secret,
          response: token,
          subject: subject,
          nome: nome,
          email: email,
          phone: phone,
          msg: msg
        }
        const response = await fetch(send_url, {
          method: "POST",
          body: JSON.stringify(_data),
          headers: {"Content-type": "application/json; charset=UTF-8"}
        });
  
        const dataResponse = await response;
  
        setLoading(false);
        alert(
          "Seu contato foi enviado com sucesso! Em breve entraremos em contato."
        );
  
        setNome("");
        setEmail("");
        setPhone("");
        setMsg("");

        return true;  

      } catch (err) {
        setLoading(false);
        alert("Erro ao tentar enviar seu contato. Por favor tente novamente");
        console.log(err);
        return false;
      } finally {
        setLoading(false);
      } 
    }
  }

  return (
    <S.ContactContainer id="contact">
      <S.ContentContact>
        <S.SocialNetworkContainer>
          <h3>Contato</h3>
          <S.PositionNetwork>
            <div>
              <p>Whatsapp</p>
              <a
                href="https://web.whatsapp.com/send?phone=5541992312727"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <Button bgColor="#31AA54">
                  <BsWhatsapp />
                  <span>(41) 99231-2727</span>
                </Button>
              </a>
            </div>
            <div>
              <p>Telefone</p>
              <a
                href="tel:554130146283"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <Button>
                  <BsFillTelephoneFill />

                  <span>(41) 3014-6283</span>
                </Button>
              </a>
            </div>
          </S.PositionNetwork>
          <S.LocationContainer>
            <aside>
              <p>Localização</p>
              <S.TextLocation>
                <BsFillGeoAltFill style={{ marginRight: "5px" }} color="red" />
                Rua Estados Unidos, 1579
              </S.TextLocation>
              <S.TextState>Bacacheri - Curitiba - PR</S.TextState>
            </aside>
            <S.MapContainer>
              <Button
                onClick={() =>
                  window.open("https://g.page/crossfitguepardo?share")
                }
              >
                <BsFillCursorFill />
                Adicionar Rota
              </Button>
            </S.MapContainer>
          </S.LocationContainer>
        </S.SocialNetworkContainer>
        <S.FormContainer>
          <S.TitleForm>
            Você pode entrar em contato conosco através do formulário abaixo
            também.
          </S.TitleForm>
          <S.InputText
            label="Nome"
            variant="outlined"
            required
            value={nome}
            type="text"
            onChange={(e) => setNome(e.target.value)}
          />
          <S.InputText
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
          />
          <S.InputText
            label="Telefone"
            value={phone}
            onChange={(e) => setPhone(maskPhone(e.target.value))}
            variant="outlined"
            required
            type="tel"
          />
          <S.InputText
            label="Mensagem"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            variant="outlined"
            type="text"
          />
          <S.ConfirmContainer>
            <aside>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={changeValue}
              />
            </aside>
            <div>
              <Button
                mh="52px"
                onClick={sendForm}
                disabled={isVerify || isLoading}
              >
                {isLoading ? "aguarde..." : "enviar"}
              </Button>
            </div>
          </S.ConfirmContainer>
        </S.FormContainer>
      </S.ContentContact>
    </S.ContactContainer>
  );
}

export { Contact };
