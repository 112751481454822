import React from "react";
import { Benefits } from "../../components/Benefits";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";
import Main from "../../components/Main";
import { SectionCard } from "./components/SectionCard";
import Squat from "../../assets/images/WrapperContent/squat-content-two.jpg";
import Bar from "../../assets/images/WrapperContent/bar-content.png";
import Cashier from "../../assets/images/WrapperContent/cashier-content.png";
import Corrida from "../../assets/images/WrapperContent/corrida.jpg";
import PersonalImg from "../../assets/images/WrapperContent/personal.jpg";
import { useMediaQuery } from "@material-ui/core";

function Personal() {
  const isMobile = useMediaQuery("(max-width: 530px)");

  return (
    <section>
      <Main
        bgImage={isMobile ? "/banner-personal-mobile.jpg" : "/persona-bg.png"}
        title={"VENHA NOS CONHECER"}
        redirect="#contact"
      />
      <SectionCard
        image={Squat}
        titleCard="Planilhas"
        paragraphCard="O serviço de planilha de treino é voltado para o aluno que prefere uma  programação de treinos individualizada, montada de acordo com seus objetivos específicos, como uma competição ou um teste físico par concursos públicos. Mais indicado para alunos que possuem um nível físico e técnico a partir do nível intermediário. As planilhas são muito utilizadas para treinos extras, após ou anterior a aula em grupos. É necessário observar as regras para utilização da sala de treino nestes casos."
        colorTitle="#FEB415"
        color="white"
      />
      <SectionCard
        image={PersonalImg}
        titleCard="Personal Trainer"
        paragraphCard="O serviço de personal trainer é voltado para o público que quer ter um acompanhamento exclusivo de um coach nas suas sessões de treino. Seja porque deseja aperfeiçoar alguma técnica ou demanda específica, ou ainda, prefere aulas individuais."
        bgColor="#FEB415"
      />
      <Benefits />
      <Contact subject="Contato via página Personal" />
      <Footer />
    </section>
  );
}

export { Personal };
