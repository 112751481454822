import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const WrapperHeaderContainer = styled.div`
  width: 100%;
  padding-top: 53px;

  @media (max-width: 1068px) {
    padding-top: 0;
  }

  @media (max-width: 481px) {
    background-color: black;
    padding: 0.5rem 0.5rem 0.6rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
`;

const ContainerHeader = styled.header`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  @media (max-width: 1068px) {
    justify-content: center;
  }

  > img {
    width: 142px;
    height: 30px;
  }
`;

const NavigationHeader = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 10px;

  @media (max-width: 1337px) {
    margin-left: 4px;
  }

  span:last-child {
    display: none;
  }

  @media (max-width: 1068px) {
    display: none;
  }
`;

const ItemMenu = styled.li`
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 1337px) {
    font-size: 12px;
  }

  ~ span {
    color: white;
  }

  > a {
    text-decoration: none;
    color: white;
  }
`;

const PositionMenuMobile = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -30px;
  background: none;

  @media (max-width: 1068px) {
    display: block;
  }
`;

const DisplayMenuMobile = styled.input`
  display: none;

  :checked {
    ~ aside {
      transform: translateX(0);
      position: fixed;
    }

    ~ label > div > section {
      position: fixed;
    }

    ~ label > div > section > div {
      transform: rotate(45deg);
      :before {
        transform: rotate(90deg);
        top: 0px;
      }
      :after {
        transform: rotate(90deg);
        bottom: 0px;
      }
    }
  }
`;

const ImageCheetah = styled.img`
  @media (max-width: 1068px) {
    margin-top: 10px;
  }
  @media (max-width: 1337px) {
    width: 152px;
  }
`;

const ModalFitness = styled.div`
  padding: 20px;
  overflow: hidden;
  > div {
    margin-top: 30px;
  }
  > div > button {
    float: right;
    width: 20%;
    margin-top: 10px;
  }
`;

const TextoModalFitness = styled.div`
  margin-top: 20px;
  overflow: hidden;
  > h3 {
    font-size: 20px;
    color: black;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  > p {
    float: left;
    width: 60%;

    @media (max-width: 515px) {
      width: 100%;
    }

    > button {
      margin-top: 20px;
    }
  }
`;

const ImageFitness = styled.img`
  width: 30%;
  margin-right: 20px;
  float: left;
  @media (max-width: 515px) {
    margin-left: calc(50% - 75px);
    width: 150px;
    margin-top: 0px;
    position: relative;
    float: none;
    margin-bottom: 20px;
  }
`;

const InputTextFitness = styled(TextField)`
  background: #f0f0f0;
  color: #54565a;
  float: left;
  width: 70%;
  > label {
    font-weight: bold;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export {
  WrapperHeaderContainer,
  ContainerHeader,
  NavigationHeader,
  ItemMenu,
  PositionMenuMobile,
  DisplayMenuMobile,
  ImageCheetah,
  ModalFitness,
  ImageFitness,
  InputTextFitness,
  TextoModalFitness,
};
