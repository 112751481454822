import styled from "styled-components";

const SectionCardContainer = styled.section`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || "black"};
`;

const ContentCard = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  height: 70%;
  padding: 20px 5px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 350px;

  > h3 {
    max-width: ${({ mw }) => (mw ? "72%" : "52%")};
    font-size: 30px;
    color: ${({ colorTitle }) => colorTitle || "black"};
    font-weight: 900;
    margin-bottom: 30px;

    @media (max-width: 332px) {
      max-width: 90%;
    }
  }

  > p {
    color: ${({ color }) => color || "black"};
    max-width: 90%;
    font-size: 16px;
  }
`;

const ContentImage = styled.div`
  display: flex;
  align-items: flex-end;

  > img {
    height: 80%;
    width: 392px;
    max-width: 100%;
    margin-bottom: 30px;
    border-radius: ${({ br }) => br};
    object-fit: cover;
  }
`;

const ContainerFooter = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  color: #feb415;
  gap: 10px;
  margin: 30px 0 10px;
  padding-left: 25px;

  @media (max-width: 307px) {
    padding-left: 0;
  }

  > span {
    font-size: 20px;
  }
`;

export {
  SectionCardContainer,
  ContentCard,
  ContentText,
  ContentImage,
  ContainerFooter,
};
