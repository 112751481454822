import ana from "../../assets/images/TeamCard/ana-diretora-geral.png";
import thiago from "../../assets/images/TeamCard/thiago.png";
import luiza from "../../assets/images/TeamCard/luiza.png";
import johny from "../../assets/images/TeamCard/jhony.png";
import calixto from "../../assets/images/TeamCard/calixto.png";
import celso from "../../assets/images/TeamCard/celso.png";

const LIST_MEMBERS = [
  {
    memberImageTeam: ana,
    nameMember: "Ana Leandro",
	cargoMember: "Diretora geral",
	linkMember: "https://instagram.com/analeandro.fit?utm_medium=copy_link"
  },
  {
    memberImageTeam: celso,
    nameMember: "Celso",
	cargoMember: "Coach",
	linkMember: "https://instagram.com/celsovictor01?utm_medium=copy_link"
  },
  {
    memberImageTeam: thiago,
    nameMember: "Thiago",
	cargoMember: "Coach",
	linkMember: "https://instagram.com/thiagoaldigueri?utm_medium=copy_link"
  },
  {
    memberImageTeam: luiza,
    nameMember: "Luiza",
	cargoMember: "Coach",
	linkMember: "https://instagram.com/coachlubarros?utm_medium=copy_link"
  },
  {
    memberImageTeam: johny,
    nameMember: "Jhony",
	cargoMember: "Coach",
	linkMember: "https://instagram.com/treinador.jhony?utm_medium=copy_link"
  },
  {
    memberImageTeam: calixto,
    nameMember: "Tiago Calixto",
	cargoMember: "Coach",
	linkMember: "https://instagram.com/tiagocalixtopersonal?utm_medium=copy_link"
  },
];

export { LIST_MEMBERS };
