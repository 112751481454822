const LIST_FEEDBACK = [
  {
    imagePerson: "/luiz.png",
    titleFeedback: "Uma vida completamente nova",
    messageFeedback:
      "Na Guepardo aprendi a enfrentar os meus limites com treino e dedicação. Os resultados foram rápidos e cada vez mais motivantes. Ampliei meu meio social com muitos novos amigos e eventos. Já treinei em diversas academias mas definitivamente a melhor de todas é a Guepardo! A estrutura impecável,o time de coaches é excelente e os equipamentos de perfeita qualidade!",
    personFeedback: "Luiz Ceniz",
  },
  {
    imagePerson: "jonatas.png",
    titleFeedback: "De workaholic para fitness",
    messageFeedback:
      "Tenho 47 anos, sou executivo de uma empresa de TI e  era sedentário, até conhecer de perto o trabalho da Guepardo. Morava em São Paulo e tinha uma vida dedicada ao trabalho, um workaholic! Com a pandemia e a possibilidade do trabalho remoto me mudei para Curitiba, me matriculei na Guepardo e comecei aos poucos, afinal não fazia exercício físico desde o colégio, rs! Perdi 20kg, usava calça n. 52 hoje uso 38, passei a treinar todos os dias e isso contribuiu  para uma alimentação e sono infinitamente melhor. Hoje tenho mais saúde física e mental, meu nível de ansiedade e estresse melhorou muito, possibilitando uma performance no trabalho ainda melhor. Estou muito feliz e realizado, adoro a Guepardo!  ",
    personFeedback: "Jonatas Leandro",
  },
{
    imagePerson: "/murilo.png",
    titleFeedback: "Estrutura exemplar",
    messageFeedback:
      "Sempre gostei de treinar Crossfit, e a Guepardo apareceu em um momento em que eu mais precisava. Os coaches são muito atenciosos e tecnicamente perfeitos! Gosto dos treinos que são preparados semanalmente, pois são treinos coerentes, ou seja, eles respeitam a individualidade de cada um, preservando nossa saúde e bem-estar. Queria também parabenizar a Guepardo pela sua infraestrutura: ventiladores, chão todo emborrachado, barras, anilhas e ergômetros disponíveis para ter uma ótima experiência de treino, além disso, os vestiários são incríveis (uau!),  com duchas ótimas para tomar um banho pós treino. Adoro estar na Guepardo e treinar com o pessoal todos os dias!",
    personFeedback: "Murilo Ferracini",
  },

];
export { LIST_FEEDBACK };
