import React from "react";
import { LIST_BENEFITS } from "../../__mocks__/GymBenefits";

import * as S from "./styles";

function Benefits() {
  return (
    <S.ListBenefitsContainer>
      <h2>Guepardo para você</h2>
      <S.ListBenefits>
        {LIST_BENEFITS.map((image) => (
          <img
            key={image}
            src={image}
            alt="imagem dos beneficios da academia"
          />
        ))}
      </S.ListBenefits>
    </S.ListBenefitsContainer>
  );
}

export { Benefits };
