import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'open sans', 'helvetica';
    scroll-behavior: smooth;
  }

  a {
    text-decoration: none
  }
`;
