import styled from "styled-components";

const PlanCardContainer = styled.div`
  width: 100%;
  max-width: ${({ mw }) => mw || "378px"};
  min-height: ${({ mh }) => mh || "300px"};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ jc }) => jc};
  background-color: ${({ bgMaster }) => bgMaster || "white"};
`;

const PlanTypeContainer = styled.div`
  padding: 0 30px;
  width: 90%;

  > h2 {
    color: white;
    background-color: ${({ headerColor }) => headerColor};
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    padding: 2px;
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background-color: ${({ headerColor }) => headerColor};
  color: ${({ headerFontColor }) => headerFontColor};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > span {
    font-size: 22px;
    margin-bottom: 5px;

    @media (max-width: 385px) {
      font-size: 16px;
    }
  }

  > div {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    background-color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 110px;
    color: black;
  }
`;

const PlanCardContent = styled.div`
  padding: 0 20px;
  width: 100%;
`;

const MonthlyValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  > span {
    font-size: 19px;
  }
`;

const PlanDescription = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div:last-child {
    margin-bottom: 40px;
  }
`;

const PlanItem = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const ItemMarker = styled.div`
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor || "#54565A"};
  min-width: 10px;
  height: 10px;

  ~ span {
    font-size: 15px;
    text-align: start;
  }
`;

const ButtonContainer = styled.footer`
  width: 120px;
  margin-top: 30px;
  position: absolute;
  bottom: ${({ bt }) => bt || "-17px"};
`;

export {
  PlanCardContainer,
  PlanTypeContainer,
  HeaderContainer,
  MonthlyValue,
  PlanCardContent,
  PlanDescription,
  PlanItem,
  ItemMarker,
  ButtonContainer,
};
