import styled from "styled-components";
import { HeaderContainer } from "../PlanCard/styles";

const WrapperContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  margin: 50px 0 84px 0;
  padding: 0 10px;

  @media (max-width: 935px) {
    margin-top: 30px;
  }
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 450px;
  text-align: center;
  margin-top: 25px;

  > p > ul {
    list-style: none;
  }

  > p > ul > li {
    padding-bottom:5px;
  }
  
  > p > ul > li:before {
    content: '✓';
    padding-right:3px;
    font-weight:bold;
  }

  > h3 {
    font-weight: 900;
    font-size: 32px;
    max-width: 68%;

    @media (max-width: 975px) {
      font-size: 25px;
    }
  }
`;

const ContentImage = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 450px;
  padding-top: 60px;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    > img {
      object-fit: cover;
    }
  }
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
  position: relative;
  margin-bottom: 70px;

  > a > button {
    position: absolute;
    bottom: -86px;
    left: calc(26% - 20px);
  }
`;

const Header = styled(HeaderContainer)`
  > span {
    @media (max-width: 306px) {
      font-size: 15px;
    }
  }
`;

export { WrapperContainer, ContentText, ContentImage, CardContent, Header };
