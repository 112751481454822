import hygiene from "../../assets/images/GymBenefits/kit-higienizacao.png";
import tecnofit from "../../assets/images/GymBenefits/destaque-app-tecnofit.png";
import boutique from "../../assets/images/GymBenefits/destaque-vestiario-conceito-boutique.png";
import security from "../../assets/images/GymBenefits/destaque-seguranca.png";
import reductionOfStudents from "../../assets/images/GymBenefits/destaque-numero-reduzido-de-alunos.png";
import parking from "../../assets/images/GymBenefits/destaque-estacionamento.png";
import coaches from "../../assets/images/GymBenefits/destaque-coaches.png";
import fundamentalClasses from "../../assets/images/GymBenefits/destaque-aulas-fundamentais.png";

const LIST_BENEFITS = [
  hygiene,
  tecnofit,
  fundamentalClasses,
  coaches,
  parking,
  reductionOfStudents,
  security,
  boutique,
];

export { LIST_BENEFITS };
