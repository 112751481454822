import styled from "styled-components";

const ContainerFooter = styled.footer`
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 76px 0;
  margin-top: 50px;
`;

const HeaderFooter = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ContactFooter = styled.div`
  margin-left: 78px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 920px) {
    margin: 0px 17px;
  }
`;

const AcademyCertification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 315px;

  @media (max-width: 920px) {
    margin: 0px 17px;
  }
`;

const ContactContent = styled.div`
  display: flex;
  gap: 100px;
  flex-wrap: wrap;

  @media (max-width: 920px) {
    gap: 44px;
    margin-top: 52px;
    width: 100%;
  }
`;

const TextFooter = styled.p`
  color: white;
  text-align: ${({ textAlign }) => textAlign || "start"};
  line-height: ${({ lh }) => lh || "none"};

  @media (max-width: 920px) {
    margin: 15px 0 0 10px;
  }
`;

const TitleFooter = styled.h3`
  color: white;
  margin-bottom: 10px;
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const OpeningHoursContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 120px;

  @media (max-width: 807px) {
    gap: 14px;
    flex-direction: column;
  }
`;

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 30px;

  @media (max-width: 920px) {
    gap: 10px;
  }
`;

const LogoPartners = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 585px) {
    flex-direction: column;
    gap: 58px;
    margin-top: 35px;
  }
`;

export {
  ContainerFooter,
  HeaderFooter,
  ContactFooter,
  AcademyCertification,
  ContactContent,
  TextFooter,
  TitleFooter,
  OpeningHoursContainer,
  PartnersContainer,
  LogoPartners,
};
