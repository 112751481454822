import styled from "styled-components";

const FeedbackContainer = styled.div`
  width: 100%;
  max-width: 295px;
  margin: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 350px) {
    max-width: 258px;
  }

  @media (max-width: 920px) {
    gap: 5px;
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 350px) {
    max-width: 248px;
  }

  > h3 {
    color: #ffa500;
    max-width: 130px;
    height: 132px;
    font-weight: 300;
    font-size: 19px;
    overflow-x: hidden;
    display: flex;
    align-items: center;

    @media (max-width: 585px) {
      overflow-y: scroll;
    }
  }
`;

const StudentImage = styled.div`
  width: 115px;
  height: 115px;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: 115px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;

  @media (max-width: 350px) {
    height: 90px;
    width: 100px;
  }

  > img {
    position: absolute;
    top: -8px;
    left: -18px;

    @media (max-width: 350px) {
      left: -9px;
      width: 33px;
    }
  }
`;



const ShowMoreLink = styled.p`
	font-size:10px;
	cursor:pointer;
	color:#ffa500;
	text-align:center;
`;


const FeedbackPerson = styled.h4`
  font-weight: 400;
  text-align: center;
  font-size: 22px;
`;

const MessageFeedback = styled.div`
  text-align: center;
  line-height:120%;
  font-size:14px;
  height:${props => props.showFullText?'auto':'100px'};
  overflow:${props => props.showFullText?'visible':'hidden'};
`;

export {
  FeedbackContainer,
  HeaderContainer,
  StudentImage,
  FeedbackPerson,
  MessageFeedback,
  ShowMoreLink
};
