import Academy1 from "../../assets/images/image-academy1.jpg";
import Academy2 from "../../assets/images/image-academy2.jpg";
import Academy3 from "../../assets/images/image-academy3.jpg";

const IMAGES_CAROUSEL = [
  {
    id: 2,
    src: Academy1,
    alt: "Foto da fachada",
  },
  {
    id: 3,
    src: Academy2,
    alt: "Foto vestiário 1",
  },
  {
    id: 4,
    src: Academy3,
    alt: "Foto vestiário 2",
  },
];

export { IMAGES_CAROUSEL };
