import React from "react";
import { AthleteCardMini } from "../../components/AthleteCard";
import Main from "../../components/Main";
import AthleticAvaliation from "../../assets/images/card/athletic-avaliation.png";
import { ListCardContainer } from "../Home/styles";
import {
  WrapperContent,
  WrapperContent_Avaliacao,
} from "../../components/WrapperContent";
import { Benefits } from "../../components/Benefits";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";
import { useMediaQuery } from "@material-ui/core";

const MOCK_CARD = {
  imageCard: AthleticAvaliation,
  titleCard: "Avaliação Física In Body",
  paragraphCard:
    "A experiência GUEPARDO começa pela avaliação corporal. Ela é é extremamente importante para o acompanhamento real dos resultados.<br /><br />Somos adeptos do uso da mais alta tecnologia e buscamos oferecer o melhor para nossos alunos. Para as avaliações físicas, contamos com a tecnologia InBody270, equipamento muito utilizados por médicos da área de nutrologia."
};

function Avaliation() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <section>
      <Main
        bgImage={
          isMobile ? "/avaliacao-fisicamobile.jpg" : "/avaliation-bg.png"
        }
        title={"AGENDE SUA AVALIAÇÃO"}
        redirect="#contact"
      />
      <ListCardContainer>
        <AthleteCardMini noContent {...MOCK_CARD} />
      </ListCardContainer>
      <WrapperContent_Avaliacao
        titleText="Como é feita a avaliação"
        paragraph="<ul>
        <li>Percentual de gordura corporal</li>
        <li>Massa magra de cada segmento corporal</li>
        <li>Massa muscular</li>
        <li>Taxa metabólica basal</li>
        <li>Grau de obesidade</li>
        <li>Água corporal total </li>
        <li>Proteína e Minerais</li>
        <li>Massa de gordura corporal</li>
        <li>Estimativa de gordura de cada segmento corporal</li>
        <li>Relação cintura-quadril</li>
        <li>Peso ideal baseado na composição corporal</li>
        <li>Controle de gordura </li>
        <li>Controle de músculo</li></ul>"
        card
      />
      <Benefits />
      <Contact subject="Contato via página de Avaliação Física" />
      <Footer />
    </section>
  );
}
export { Avaliation };
