import styled from "styled-components";

const CardContainer = styled.div`
  width: 100%;
  max-width: 650px;
  background-color: black;
  padding: 30px 10px;
  z-index: 5;
  margin-top: 30px;
`;

const CardContainerMini = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: black;
  padding: 30px 10px;
  z-index: 5;
  margin-top: 30px;



`;

const HeaderCard = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: -1;
  width: 100%;
`;

const TitleCard = styled.h1`
  color: #ffa500;
  position: absolute;
  bottom: -10px;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 2px;
  text-align: center;
  /* width: 500px; */
`;

const ParagraphCard = styled.p`
  margin-top: 25px;
  color: white;
  z-index: 2;
  text-align: center;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: 515px) {
    padding:0px 20px;
  }
`;

const ParagraphCard2 = styled.p`
  margin-top: 25px;
  color: white;
  z-index: 2;
  text-align: center;
  font-weight: 400;
  line-height: 130%;
  display:inline-block;
  width:300px;
`;


const ImageCard = styled.img`
  height: 400px;
  max-width: 400px;
  width: 100%;
  @media (max-width: 515px) {
    height:auto;
  }  
`;

const KnowMore = styled.p`
  margin-top: 25px;
  text-align: center;
  color: #ffa500;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;

  &:after {
    margin-left: 10px;
    content: ">";
  }

  > a {
    color: #ffa500;
  }
`;

export {
  CardContainer,
  CardContainerMini,
  HeaderCard,
  TitleCard,
  ParagraphCard,
  ImageCard,
  KnowMore,
  ParagraphCard2
};
