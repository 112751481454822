import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cheetah from "../../assets/images/text-guepardo.svg";
import { LIST_MENU } from "../../__mocks__/Header/menu";
import { HamburguerMenu } from "../HambuguerMenu";
import { ContentMenuMobile } from "./ContentMenuMobile";
import * as S from "./styles";
import ModalFit from "./ModalFit";

function Header() {
  const [check, setCheck] = useState(false);
  const [doShowFitness, setdoShowFitness] = useState(false);

  return (
    <S.WrapperHeaderContainer>
      <S.ContainerHeader>
        <ModalFit open={doShowFitness} setOpen={setdoShowFitness} />
        <S.DisplayMenuMobile
          type="checkbox"
          onChange={() => setCheck(!check)}
          checked={check}
          id="checked"
        />
        <ContentMenuMobile setCheck={setCheck} check={check} />
        <label htmlFor="checked">
          <S.PositionMenuMobile>
            <HamburguerMenu />
          </S.PositionMenuMobile>
        </label>
        <Link to="/">
          <S.ImageCheetah src={Cheetah} alt="guepardo branco" />
        </Link>
        <S.NavigationHeader>
          {LIST_MENU.map(({ menuItem, redirect }) => (
            <React.Fragment key={menuItem}>
              <S.ItemMenu>
                {menuItem !== "Fitness" ? (
                  <a href={redirect}>{menuItem !== "Home Page" && menuItem}</a>
                ) : (
                  <a onClick={() => setdoShowFitness(true)}>Fitness</a>
                )}
              </S.ItemMenu>
              <span>{menuItem !== "Home Page" && "|"}</span>
            </React.Fragment>
          ))}
        </S.NavigationHeader>
      </S.ContainerHeader>
    </S.WrapperHeaderContainer>
  );
}

export { Header };
