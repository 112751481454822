import React from "react";
import Main from "../../components/Main";
import { WrapperContent } from "../../components/WrapperContent";
import CrossLogo from "../../assets/images/WrapperContent/cross-content.png";
import { PlanContainer } from "../../components/PlanContainer";
import { Benefits } from "../../components/Benefits";
import { Contact } from "../../components/Contact";
import { Footer } from "../../components/Footer";
import { MdAccessTime } from "react-icons/md";

import {
  ContainerDays,
  ListCardContainer,
  OperatingDays,
  TextClassroom,
} from "../Home/styles";
import { AthleteCardCross } from "../../components/AthleteCard";
import Image from "../../assets/images/card/image-2-card.png";
import { Button } from "../../components/Button/";
import { useMediaQuery } from "@material-ui/core";

const MOCK_CARD = {
  imageCard: Image,
  linkCard: "/crossfit",
  titleCard: "Crossfit Guepardo",
  paragraphCard1:
    "Crossfit é um programa de treinamento físico geral que mistura diferentes atividades como: exercícios com o próprio peso corporal, baseados em calistenia e movimentos ginásticos, levantamento de peso e movimentos que envolvam cargas externas e exercícios como correr, pedalar e remar entre outros. <br /><br />O Crossfit pode ser praticado por todas as pessoas, sem restrições médicas, mesmo quem nunca tenham praticado nenhum tipo de exercício físico. A magia está em possibilitar modificações ou adaptações dos movimentos, da carga, do volume (quantidade) e intensidade (velocidade) do treino, dependendo do nível de cada praticante",
  paragraphCard2:
    "Temos na Guepardo pessoas iniciantes treinando com pessoas mais avançadas e até pessoas que se tornaram competidores em campeonatos de Crossfit e Fitness.<br /><br />CrossFit é a maior plataforma fitness do mundo com academias (box) afiliadas oficialmente e nós fazemos parte desta comunidade, como Crossfit Guepardo, em Curitiba. <br /><br />Para nós da Guepardo o Crossfit vai do programa de exercício e condicionamento geral, ao esporte como hobby ou competitivo, acima de tudo uma marca forte e mundialmente conhecida.",
};

function Crossfit() {
  const isMobile = useMediaQuery("(max-width: 515px)");

  return (
    <section>
      <Main
        bgImage={isMobile ? "/banner-crossfit-mobile.jpg" : "/cross-bg.png"}
        title={"VAMOS COMEÇAR"}
        redirect="#contact"
      />
      <ListCardContainer>
        <div>
          <AthleteCardCross noContent {...MOCK_CARD} />
        </div>
        <ContainerDays>
          <Button bgColor="#ffa500">
            <MdAccessTime />
            <TextClassroom>Aulas</TextClassroom>
            <div>
              <OperatingDays>
                Seg a Sex às 6h, 8h, 10h, 12h, 14h, 17h, 18h e 20h
              </OperatingDays>
              <OperatingDays>Sábados às 8h e 10h</OperatingDays>
            </div>
          </Button>
        </ContainerDays>
      </ListCardContainer>
      <WrapperContent
        logo={CrossLogo}
        titleText="Conheça nossa estrutura"
        paragraph="A experiência GUEPARDO faz toda a diferença. Aqui você se matricula em um box conceito premium e é atendido por profissionais altamente qualificados e treinados constantemente.<br /><br />
        Nós prezamos muito pela estrutura oferecida aos nossos alunos, desde os vestiários com conceito internacional aos equipamentos e a limpeza. Todo cuidado é essencial.
        Nossa equipe está disponível para o seu desenvolvimento e pronta para te ajudar a
        conquistar seus objetivos e metas. Ela é formada por profissionais certificados pela CrossFit e pelo conselho regional de educação física, desde a diretora-geral, gerente até os treinadores.<br /><br />
        Os treinos são prescritos cuidadosamente pela Head Coach da Guepardo (Coach Vivianne), que atua na Guepardo há 5 anos."
      />
      <PlanContainer />
      <Benefits />
      <Contact subject="Contato via página Crossfit" />
      <Footer />
    </section>
  );
}

export { Crossfit };
