import React, { useState } from "react";
import Quotation from "../../assets/images/card/quotation-mark.png";

import * as S from "./styles";

function FeedbackCard({
  imagePerson,
  titleFeedback,
  messageFeedback,
  personFeedback,
}) {
	
	const [showFullText, setshowFullText] = useState(false);

	function toggleFeedbackText(e)
	{
		setshowFullText(!showFullText);	
		
	}
	
	
  return (
    <S.FeedbackContainer>
      <S.HeaderContainer>
        <S.StudentImage bgImage={imagePerson}>
          <img src={Quotation} width="45px" alt="Imagem das aspas" />
        </S.StudentImage>
        <h3>{titleFeedback}</h3>
      </S.HeaderContainer>
      <S.MessageFeedback showFullText={showFullText}>"{messageFeedback}"</S.MessageFeedback>
	  <S.ShowMoreLink onClick={toggleFeedbackText}>{showFullText?'ver menos':'ver mais...'}</S.ShowMoreLink>
      <S.FeedbackPerson>{personFeedback}</S.FeedbackPerson>
    </S.FeedbackContainer>
  );
}

export { FeedbackCard };
