import { Avaliation } from "../pages/Avaliation";
import { Crossfit } from "../pages/Crossfit";
import { Fitness } from "../pages/Fitness";
import { Home } from "../pages/Home";
//import { Nutrition } from "../pages/Nutrition";
import { Personal } from "../pages/Personal";

const LIST_ROUTES = [
  {
    element: <Home />,
    path: "/",
  },
  {
    element: <Crossfit />,
    path: "/crossfit",
  },
  {
    element: <Avaliation />,
    path: "/avaliacao",
  },
  {
    element: <Personal />,
    path: "/personal",
  },
/*  {
    element: <Nutrition />,
    path: "/nutricao",
  },*/
  {
    element: <Fitness />,
    path: "/fitness",
  },
];

export { LIST_ROUTES };
