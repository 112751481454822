import styled from "styled-components";

const PlanContainerMaster = styled.section`
  background-color: #f4f6f6;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    color: #3e3838;
    font-size: 43px;
    font-weight: 400;
    margin-bottom: 50px;
  }
`;

const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 960px;
  margin-top: 50px;

  @media (max-width: 752px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 35px;
  width: 358px;
  margin-top: 20px;

  > div > aside {
    width: 87%;
    margin: -20px auto 22px;
  }

  @media (max-width: 752px) {
    width: 100%;
    margin: 100px 0;
    max-width: 500px;
  }
`;

const HeaderOneWrapper = styled.div`
  background-color: white;
  width: 358px;

  > aside {
    width: 87%;
    margin: -20px auto 20px;
  }

  @media (max-width: 752px) {
    width: 100%;
  }
`;

const BestSellerContainer = styled.div`
  max-width: 368px;
  width: 100%;

  @media (max-width: 752px) {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }

  > aside {
    width: 87%;
    margin: -20px auto 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 20px;
  }
`;

const SpecialConditions = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 600px;

  @media (max-width: 752px) {
    margin-top: 0;
  }

  > p {
    font-size: 22px;
    text-transform: none;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 1.3px;
    font-weight: bold;
  }

  > section {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 25px auto;
    max-width: 244px;

    ~ a > button {
      display: flex;
      gap: 30px;
      text-transform: none;
      font-size: 16px;
      width: 80%;
      margin: 35px auto;
      max-width: 286px;

      @media (max-width: 320px) {
        gap: 10px;
        width: 100%;
      }

      > img {
        transform: rotate(87deg);
        width: 20px;
        height: 47px;

        @media (max-width: 320px) {
          width: 30px;
        }
      }
    }
  }
`;

const FooterPlan = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  text-transform: none;

  > p {
    font-size: 15px;
    text-transform: none;
  }

  > a {
    width: 25%;
    @media (max-width: 460px) {
      width: 100%;
    }
    > button {
      text-transform: none;
    }
  }

  @media (max-width: 460px) {
    gap: 15px;
  }
`;

const SpacingContainer = styled.div`
  width: 100%;
  height: 330px;
  background-color: white;
`;

export {
  PlanContainerMaster,
  ContentCard,
  WrapperCard,
  BestSellerContainer,
  SpecialConditions,
  FooterPlan,
  HeaderOneWrapper,
  SpacingContainer,
};
