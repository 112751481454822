import React from "react";
import { Header } from "../Header";
import { Button } from "../Button";
import ScheduleYourTime from "../ScheduleYourTime";
import CheetahWhite from "../../assets/images/guepardo-yellow.svg";

import * as S from "./styles";
import { useMediaQuery } from "@material-ui/core";

export default function Main({ bgImage, title, redirect }) {
  const isMobile = useMediaQuery("(max-width: 481px)");
  return (
    <React.Fragment>
      {isMobile && <Header />}
      <S.HeaderContainer bgImage={bgImage}>
        {!isMobile && <Header />}
        <S.ContentHeader>
          <S.MotivationMessageContainer>
            <img
              src={CheetahWhite}
              width={isMobile ? "140px" : "165px"}
              alt="imagem do guepardo amarelo"
            />
            <S.MotivationMessage>
              A experiência que transforma
            </S.MotivationMessage>
            <S.SizeButtonContainer>
              <a href={redirect}>
                <Button>{title != null ? title : "AGENDE SUA CONSULTA"}</Button>
              </a>
            </S.SizeButtonContainer>
          </S.MotivationMessageContainer>
          <S.PositionScheduleContainer>
            <div />
            <ScheduleYourTime />
            <div />
          </S.PositionScheduleContainer>
        </S.ContentHeader>
      </S.HeaderContainer>
    </React.Fragment>
  );
}
