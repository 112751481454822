import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";


function AthleteCard({ imageCard, titleCard, linkCard, paragraphCard }) {
  return (
    <S.CardContainer>
      <S.HeaderCard>
        <S.ImageCard src={imageCard} alt="imagem dinâmica do card" />
        <S.TitleCard>{titleCard}</S.TitleCard>
      </S.HeaderCard>
      <S.ParagraphCard dangerouslySetInnerHTML={{__html: paragraphCard}}/>     
    </S.CardContainer>
  );
}

function AthleteCardCross({ imageCard, titleCard, linkCard, paragraphCard1, paragraphCard2  }) {
  return (
    <S.CardContainer>
      <S.HeaderCard>
        <S.ImageCard src={imageCard} alt="imagem dinâmica do card" />
        <S.TitleCard>{titleCard}</S.TitleCard>
      </S.HeaderCard>
      <S.ParagraphCard2 dangerouslySetInnerHTML={{__html: paragraphCard1}}/>     
      <S.ParagraphCard2 dangerouslySetInnerHTML={{__html: paragraphCard2}}/>     
    </S.CardContainer>
  );
}

function AthleteCardMini({ imageCard, titleCard, linkCard, paragraphCard, noContent }) {
  return (
    <S.CardContainerMini>
      <S.HeaderCard>
        <S.ImageCard src={imageCard} alt="imagem dinâmica do card" />
        <S.TitleCard>{titleCard}</S.TitleCard>
      </S.HeaderCard>
      <S.ParagraphCard dangerouslySetInnerHTML={{__html: paragraphCard}}/>
      {!noContent ? <S.KnowMore><a href={linkCard}>Saiba Mais</a></S.KnowMore> : ""}
    </S.CardContainerMini>
  );
}
export { AthleteCard, AthleteCardMini, AthleteCardCross};
