import styled from "styled-components";
import Carousel from "react-elastic-carousel";

const ContainerHome = styled.section`
  max-width: 100%;
`;

const AboutTheAcademy = styled.section`
  padding-top: 50px;
  padding-bottom:10px;
  background-color:black;
  color:white;
  line-height:130%;

  > h1 {
    font-size:1.5em;
    font-weight: bold;
    line-height: 3.4;
    text-align: center;

    @media (max-width: 975px) {
      line-height: 1.3;
    }
    @media (max-width: 515px) {
      padding:0px 20px;
    }

  }
`;

const ContentAboutAcademy = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin: 40px 0;
  padding: 0 10px;
`;

const ContainerText = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > p {
    color: #eee;

    @media (max-width: 515px) {
      padding:0px 20px;
    }
  
  }

  > div {
    width: 60%;
  }
`;

const ListCardContainer = styled.div`
  background-color: black;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const TextClassroom = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: white;
  margin-left: 8px;
  text-transform: capitalize;
`;

const OperatingDays = styled.p`
  font-weight: 100;
  margin-left: 20px;
  text-align: start;
  line-height: 1.3;
  text-transform: none;
  padding: 0 2px;

  @media (max-width: 352px) {
    text-align: center;
  }
`;

const ContainerDays = styled.div`
  width: 100%;
  max-width: 477px;
  margin-top: 50px;
  padding: 0 10px;

  svg {
    font-size: 40px;
  }
`;

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;

  > h3 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 26px;
    padding: 0 2px;
  }
`;

const ListFeedbackContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 130px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
`;

const MembersTeam = styled.section`
  width: 100%;
  min-height: 512px;
  background-image: url("/bg-color-team.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  padding: 56px 10px;

  > h1 {
    color: white;
    text-align: center;
    font-size: 36px;
    letter-spacing: 1.6px;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
  }
`;

const ContainerCarousel = styled(Carousel)`
  width: 100%;
  max-width: 414px;

  .rec-dot {
    background: #ffa50036;
    box-shadow: 0 0 1px 3px #ffa500db;
  }

  .rec-dot_active {
    background: #ffa500;
    box-shadow: 0 0 1px 3px #ffa500;
  }
`;

const ImageCarousel = styled.img`
  height: 340px;
  object-fit: cover;
`;

export {
  ContainerHome,
  AboutTheAcademy,
  ContentAboutAcademy,
  ContainerText,
  ListCardContainer,
  TextClassroom,
  OperatingDays,
  Container,
  ContainerDays,
  ListFeedbackContainer,
  MembersTeam,
  ContainerCarousel,
  ImageCarousel,
};
