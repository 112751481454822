import styled from "styled-components";
import { ItemMenu } from "../styles";

const MenuMobileContainer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  transform: translateX(-192%);
  z-index: 102;
  transition: all 1s ease-in-out;
  background-color: #070709;
`;

const NewItemMenu = styled(ItemMenu)`
  > a {
    color: #ffa500;
    font-weight: bold;

    @media (max-width: 975px) {
      font-size: 20px;
    }
  }
`;
export { MenuMobileContainer, NewItemMenu };
