import React from "react";
import {
  LIST_BASIC_MONTHLY,
  LIST_BASIC_YEAR,
  LIST_VIP_MONTHLY,
  LIST_VIP_YEAR,
} from "../../__mocks__/PlanCard";
import { PlanCard } from "../PlanCard";
import { Button } from "../Button";
import { ItemMarker, PlanItem } from "../PlanCard/styles";
import Guepardo from "../../assets/images/guepardo-white.svg";
import { useMediaQuery } from "@material-ui/core";

import * as S from "./styles";

function PlanContainer() {
  const isMobile = useMediaQuery("(max-width: 753px)");

  return (
    <S.PlanContainerMaster>
      <p>Planos</p>
      <S.ContentCard>
        <S.WrapperCard>
          <S.HeaderOneWrapper>
            <aside>
              <Button mh="36px">O MAIS VENDIDO</Button>
            </aside>
            <S.SpacingContainer>
              <PlanCard
                planPeriod="Mensal"
                planValue="388,00"
                planQuantity="PLANO BLACK"
                planType="Contrato Recorrente"
                planItemList={LIST_BASIC_MONTHLY}
                mw={isMobile && "100%"}
              />
            </S.SpacingContainer>
          </S.HeaderOneWrapper>
          <S.SpacingContainer>
            <PlanCard
              planPeriod="Mensal"
              planValue="345,00"
              planQuantity="PLANO GOLD"
              planType="Contrato Recorrente"
              planItemList={LIST_BASIC_YEAR}
              mw={isMobile && "100%"}
            />
          </S.SpacingContainer>
        </S.WrapperCard>
        <S.BestSellerContainer>
          <section>
            <S.HeaderOneWrapper>
              <aside>
                <Button mh="36px">O MAIS VENDIDO</Button>
              </aside>
              <S.SpacingContainer>
                <PlanCard
                  planPeriod="Mensal"
                  planValue="370,00"
                  planQuantity="PLANO BLACK"
                  planType="Contrato Semestral"
                  planItemList={LIST_VIP_MONTHLY}
                  bgColor="#D12C2C"
                  mw={isMobile && "100%"}
                />
              </S.SpacingContainer>
            </S.HeaderOneWrapper>
            <S.SpacingContainer>
              <PlanCard
                planPeriod="Mensal"
                planValue="320,00"
                planQuantity="PLANO GOLD"
                planType="Contrato Semestral"
                planItemList={LIST_VIP_YEAR}
                bgColor="#D12C2C"
                mw={isMobile && "100%"}
              />
            </S.SpacingContainer>
          </section>
        </S.BestSellerContainer>
      </S.ContentCard>
      <S.SpecialConditions>
        <p>Membership</p>
        <section>
          <PlanItem>
            <ItemMarker bgColor="#FFA500" />
            <span>Fitness coaching e acompanhamento de resultados</span>
          </PlanItem>
          <PlanItem>
            <ItemMarker bgColor="#FFA500" />
            <span>Atendimentos nutricionais</span>
          </PlanItem>
          <PlanItem>
            <ItemMarker bgColor="#FFA500" />
            <span>Plano nutricional</span>
          </PlanItem>
          <PlanItem>
            <ItemMarker bgColor="#FFA500" />
            <span>Avaliação física inbody</span>
          </PlanItem>
        </section>
        <a href="#contact">
          <Button bgColor="#FFA500">
            <img src={Guepardo} alt="Guepardo branco" />
            Consulte a recepção
          </Button>
        </a>
        <S.FooterPlan>
          <p>Para outros planos, por favor, entre em contato</p>
          <a href="#contact">
            <Button>Fale Conosco</Button>
          </a>
        </S.FooterPlan>
      </S.SpecialConditions>
    </S.PlanContainerMaster>
  );
}

function PlanContainer2() {
  return (
    <S.PlanContainerMaster>
      <p>Planos</p>
      <div>Em breve</div>
    </S.PlanContainerMaster>
  );
}

export { PlanContainer, PlanContainer2 };
