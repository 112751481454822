import React from "react";
import { Button } from "../Button";
import { BsInstagram } from "react-icons/bs";

import * as S from "./styles";

function TeamCard({ memberImageTeam, nameMember, cargoMember, linkMember}) {
  return (
    <S.TeamMemberContainer>
      <S.HeaderContainer>
        <img src={memberImageTeam} alt="imagem do time" />
      </S.HeaderContainer>
      <S.TeamMemberContent>
        <S.NameMemberTeam>{nameMember}</S.NameMemberTeam>
        <S.JobType>{cargoMember}</S.JobType>
			<Button mh="36px" bgColor="#ffa500" onClick={()=>{window.open(linkMember)}}>
          <BsInstagram />
          <span>{nameMember}</span>
        </Button>
      </S.TeamMemberContent>
    </S.TeamMemberContainer>
  );
}

export { TeamCard };
