import styled from "styled-components";

const ButtonContainer = styled.button`
  width: ${({ width }) => width || " 100%"};
  min-height: ${({ mh }) => mh || "35px"};
  background-color: ${({ bgColor }) => bgColor || "#D12C2C"};
  border: none;
  border-radius: 85px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

export { ButtonContainer };
