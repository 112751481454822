import React from "react";
import InstaLogo from "../../../../assets/images/icon-insta.svg";

import * as S from "./styles";

function SectionCard({
  image,
  titleCard,
  paragraphCard,
  bgColor,
  br,
  nutri,
  ...restProps
}) {
  return (
    <S.SectionCardContainer bgColor={bgColor}>
      <S.ContentCard>
        <S.ContentText {...restProps}>
          <h3>{titleCard}</h3>
          <p>{paragraphCard}</p>
          {nutri && (
			<a href="https://instagram.com/nutriari.cristini?utm_medium=copy_link" target="_blank">
            <S.ContainerFooter>
              <img src={InstaLogo} alt="logo do instagram" />
              <span>@nutriari.cristini</span>
            </S.ContainerFooter>
			</a>
          )}
        </S.ContentText>
        <S.ContentImage br={br}>
          <img src={image} alt="imagem dos atletas da academia" />
        </S.ContentImage>
      </S.ContentCard>
    </S.SectionCardContainer>
  );
}

export { SectionCard };
